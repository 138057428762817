<template>
  <section id="success" class="proven-success-section">
    <div class="container">
      <div class="content">
        <h3>{{ $t('productsDemo.provenSuccessTitle') }}</h3>
        <h4>{{ $t('productsDemo.provenSuccessTechTitle') }}</h4>
        <p>{{ $t('productsDemo.provenSuccessTechDescription') }}</p>
        <h4>{{ $t('productsDemo.provenSuccessProfitTitle') }}</h4>
        <p>{{ $t('productsDemo.provenSuccessProfitDescription') }}</p>
        <p>{{ $t('productsDemo.learnMore') }}</p>
        <img :src="allValueImage" alt="Proven Success" class="proven-success-image" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProvenSuccess',
  computed: {
    allValueImage() {
      return this.$i18n.locale === 'en' ? require('@/assets/AllValue_en.png') : require('@/assets/AllValue_cn.png');
    }
  },
  mounted() {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    document.documentElement.style.setProperty('--scroll-margin-top', `${navbarHeight}px`);
  }
};
</script>

<style scoped>
.proven-success-section {
  background-color: #1f1f1f; /* Dark background */
  color: #e0e0e0; /* Light text color */
  padding-top: 3rem; /* Adjust top padding */
  scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; /* Add padding to the container */
}
h3 {
  font-size: 2rem; /* Adjusted font size for h3 */
  margin-bottom: 0.5rem; /* Reduce bottom margin */
  color: #e0e0e0; /* Light text color */
}
h4 {
  font-size: 1.25rem; /* Adjusted font size for h4 */
  margin-bottom: 0.5rem;
  color: #f39c12; /* Accent color */
}
p {
  font-size: 1.25rem;
  margin-bottom: 1rem; /* Reduce bottom margin */
  color: #b0b0b0; /* Slightly lighter text color */
}
.content {
  margin-bottom: 2rem;
}
.proven-success-image {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .container {
    padding: 0 1.5rem; /* Increase padding for smaller screens */
  }
}
@media (max-width: 48rem) {
  .hero-section {
    padding-top: 8rem; /* Adjust top padding for medium screens */
    scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
  }
}
</style>