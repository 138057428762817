<template>
  <section id="home" class="hero-section">
    <div class="overlay"></div>
    <div class="hero-content">
      <h1 v-html="$t('hero.title')"></h1>
      <p>{{ $t('hero.description') }}</p>
      <a :href="TELEGRAM_URL" class="cta-button" target="_blank">{{ $t('hero.cta') }}</a>

      <!-- Promotional Video -->
      <div class="promo-video">
        <video controls class="video-responsive">
          <source src="@/assets/Introduction.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </section>
</template>

<script>
import { TELEGRAM_URL } from '@/constants.js';

export default {
  name: 'HeroSection',
  data() {
    return {
      TELEGRAM_URL
    };
  },
  mounted() {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    document.documentElement.style.setProperty('--scroll-margin-top', `${navbarHeight}px`);
  }
};
</script>

<style scoped>
.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e0e0e0; /* Light text color */
  padding-top: 1rem; /* Default top padding */
  padding-bottom: 1rem; /* Reduce bottom padding */
  scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darken the background for better text visibility */
}
.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 50rem;
  padding: 0 1rem;
  margin-top: 0; /* Remove top margin */
}
h1 {
  font-size: 2.5rem; /* Increased font size */
  margin-bottom: 0.5rem; /* Adjusted margin */
  margin-top: 0; /* Remove top margin */
}
p {
  font-size: 1.25rem; /* Increased font size */
  margin-bottom: 0.5rem; /* Reduce bottom margin */
}
.promo-video {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.video-responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.cta-button {
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjusted padding */
  font-size: 1.25rem; /* Increased font size */
  color: white;
  background-color: #f39c12; /* Accent color */
  text-decoration: none;
  border-radius: 5px;
  margin-top: 1rem; /* Add top margin */
  max-width: 90vw; /* Ensure the button does not exceed the viewport width */
  white-space: normal; /* Allow text to wrap */
  text-align: center; /* Center the text */
}

@media (max-width: 48rem) {
  .hero-section {
    padding-top: 2rem; /* Adjust top padding for medium screens */
  }
  .cta-button {
    padding: 0.25rem 0.5rem; /* Reduce padding for smaller screens */
    font-size: 1rem; /* Reduce font size for smaller screens */
    max-width: 40vw; /* Reduce max width for smaller screens */
  }
}
</style>