<template>
  <section id="team" class="about-us-section">
    <div class="container">
      <!-- Team Introduction Section -->
      <div class="team-introduction">
        <h2>{{ $t('aboutUs.managementTeam') }}</h2>
        <p class="team-introduction-text">{{ $t('aboutUs.teamIntroduction') }}</p>
      </div>

      <!-- Our Partners Section -->
      <div class="our-partners">
        <h2>{{ $t('aboutUs.ourPartners') }}</h2>
        <div class="partner-logos">
          <div class="partner-logo" v-for="partner in partners" :key="partner.id">
            <img :src="partner.image" :class="['partner-logo-photo', { 'small-logo': partner.id === 4 || partner.id === 10 }]" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUsSection",
  data() {
    return {
      partners: [
        { id: 1, image: require('@/assets/Binance.png') },
        { id: 2, image: require('@/assets/Bitget.png') },
        { id: 3, image: require('@/assets/Bybit.png') },
        { id: 4, image: require('@/assets/Gate.png') },
        { id: 5, image: require('@/assets/HTX.svg') },
        { id: 6, image: require('@/assets/Kucoin.svg') },
        { id: 7, image: require('@/assets/OKX.svg') },
        { id: 8, image: require('@/assets/Mexc.svg') },
        { id: 9, image: require('@/assets/BingX.png') },
        { id: 10, image: require('@/assets/Coinex.png') },
      ]
    };
  }
};
</script>

<style scoped>
.about-us-section {
  padding: 1rem 1rem; /* Reduce padding */
  background-color: #1f1f1f; /* Dark background */
  text-align: center;
  color: #e0e0e0; /* Light text color */
  scroll-margin-top: var(--scroll-margin-top); /* Use CSS variable for scroll margin */
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.team-introduction {
  margin-bottom: 2rem;
}
.team-introduction-text {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: left; /* Align text to the left */
}
.partner-logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 items per row */
  gap: 0.5rem; /* Add gap between items */
}
.partner-logo {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}
.partner-logo-photo {
  width: 8vw; /* Set width based on viewport width */
  max-width: 100px; /* Set a maximum width */
  height: auto; /* Maintain aspect ratio */
}
.partner-logo-photo.small-logo {
  width: 5vw; /* Smaller width for specific logos */
  max-width: 80px; /* Smaller maximum width */
}
</style>